<template>
	<app tabbar :create-button="currentTab != 3" @create="navigateTo('/project_edit')">
		<tab-list-m :tab="tabList" :current="tab" @change="updateList"></tab-list-m>
		<scroll-list-m ref="list">
			<template v-slot="{data}">
				<scroll-item-m v-if="currentTab != 3">
					<div class="flex-between-center">
						<div class="code">项目编号：{{data.code}}</div>
						<div class="state"><span :style="{'color': projectState[data.state].color}">{{projectState[data.state].name}}</span></div>
					</div>
					<div class="name">{{data.name}}</div>
					<button-list-m slot="button">
						<c-button v-if="data.state == 2" @click="navigateTo('/project_edit', {id: data.id})">确认</c-button>
						<c-button v-if="data.state == 2" @click="rejectProject(data)">驳回</c-button>
						<c-button v-else-if="data.state == 31" @click="navigateTo('/project_detail', {id: data.id})">审批</c-button>
						<c-button v-else-if="data.state == 32" @click="navigateTo('/project_detail', {id: data.id})">签署</c-button>
						<c-button v-else color="gray" hollow @click="navigateTo('/project_detail', {id: data.id})">查看详情</c-button>
						<c-button v-if="data.state == 3 && data.zbdl_money_user_type == 2" color="gray" hollow @click="navigateTo('/project_bidding', {id: data.id})">设置中标企业</c-button>
						<c-button v-if="data.state == 4" @click="navigateTo('/contract_create', {projectId: data.id})">上传合同</c-button>
						<c-button v-if="data.state < 4" color="gray" hollow @click="deleteHandle(data)">删除</c-button>
					</button-list-m>
				</scroll-item-m>
				<scroll-item-m v-else>
					<div class="flex-between-center">
						<div class="code">申请时间：{{data.create_date}}</div>
						<div class="state"><span :style="{'color': personState[data.state].color}">{{personState[data.state].name}}</span></div>
					</div>
					<div class="name">{{data.project_name}}</div>
					<button-list-m slot="button">
						<c-button v-if="data.state == 2" @click="navigateTo('/person_change_detail', {id: data.id})">审批</c-button>
						<c-button v-else color="gray" hollow @click="navigateTo('/person_change_detail', {id: data.id})">详情</c-button>
					</button-list-m>
				</scroll-item-m>
			</template>
		</scroll-list-m>
		
		<approve ref="approve" @resolve="approveHandle"></approve>
	</app>
</template>

<script>
	import approve from '@/components/module/approve.vue'
	import {mapGetters} from 'vuex'
	
	export default {
		components: {
			approve
		},
		
		props: {
			tab: String
		},
		
		data() {
			return {
				tabList: [{
					type: 1,
					name: '待审批项目',
					url: '/project/list',
					param: {
						states: [2, 31, 32]
					}
				}, {
					type: 2,
					name: '项目列表',
					url: '/project/list'
				}, {
					type: 3,
					name: '人员变更审批',
					url: '/project/ry/list'
				}],
				currentTab: 1
			}
		},
		
		computed: {
			...mapGetters(['projectState', 'personState'])
		},
		
		methods: {
			updateList(data) {
				this.currentTab = data.type;
				this.$refs.list.load({
					url: data.url,
					data: data.param
				});
			},
			
			deleteHandle(data) {
				this.$confirm({
					text: '确定要删除此项目吗？',
					resolve: () => {
						this.request({
							url: '/project/delete',
							data: {
								project_id: data.id
							},
							loading: true,
							success: () => {
								this.$message({
									text: '删除成功'
								});
								this.$refs.list.reload();
							}
						});
					}
				});
			},
			
			rejectProject(data) {
				this._approveData = data;
				this.$refs.approve.reject();
			},
			
			approveHandle(code, remark, stop, next) {
				stop && stop();
				
				this.request({
					url: '/project/confirm',
					data: {
						project_id: this._approveData.id,
						state: code ? 3 : 1,
						reject_remark: remark
					},
					loading: true,
					success: data => {
						next();
						this.$message({
							text: '审批成功'
						});
						this.$refs.table.update();
					}
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>